import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TVoidCalBack } from "corexxx";

export function FullScreenOverlay({
  children,
  onClose,
}: {
  children: any;
  onClose: TVoidCalBack;
}) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        width: "100%",
        height: "100%",
        zIndex: 11,
      }}
    >
      <IconButton
        style={{ position: "fixed", top: 10, right: 10 }}
        onClick={onClose}
      >
        {" "}
        <Close />
      </IconButton>
      {children}
    </div>
  );
}
