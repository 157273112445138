import { atom, useRecoilState } from "recoil";
import { useDAppCommand } from "../libs/dweb/DAppCommand";
import { SSAlgoTradingDef } from "../libs/imported/SSAlgoTradingDef";

export type TAlgoVerseLeaning = {
  message: string;
  type: string;
  ts_insert: string;
  _id: string;
};

const BrokerAccountInfoAtom =
  atom<SSAlgoTradingDef.TBrokerAccountInformation | null>({
    key: "BrokerAccountInfoAtom",
    default: null,
  });

const LiveQuoteMapAtom = atom<SSAlgoTradingDef.TLiveQuoteMap>({
  key: "LiveQuoteMapAtom",
  default: {},
});

const AlgoVerseLeaningAtom = atom<TAlgoVerseLeaning[]>({
  key: "AlgoVerseLeaningAtom",
  default: [],
});

const ErrorAtom = atom<string | null>({
  key: "ErrorAtom",
  default: null,
});

const FocusTickerAtom = atom<string>({
  key: "FocusTickerAtom",
  default: "AAPL",
});

const AlgoVerseConfigAtom = atom<
  SSAlgoTradingDef.TAlgoVerseAccountConfig | undefined
>({
  key: "AlgoVerseConfigAtom",
  default: undefined,
});

export const useAlgoVerse = () => {
  const appCommand = useDAppCommand(); // app command
  let [BrokerAccountInfo, setBrokerAccountInfo] = useRecoilState(
    BrokerAccountInfoAtom,
  );
  let [liveQuote, setLiveQuote] = useRecoilState(LiveQuoteMapAtom);
  let [learning, setLearning] = useRecoilState(AlgoVerseLeaningAtom);
  let [error, setError] = useRecoilState(ErrorAtom);
  let [focusTicket, setFocusTicker] = useRecoilState(FocusTickerAtom);
  let [currentAlgoVerse, setCurrentAlgoverse] =
    useRecoilState(AlgoVerseConfigAtom);

  // function
  async function loadBrokerAccountInfo(
    brokerType: SSAlgoTradingDef.TBrokerType,
  ) {
    if (brokerType == "SCHWAB") {
      try {
        // let data = await SSAlgoTradingDef.getA(appCommand.accountState?.auth_token_ || '')
        // setCurrentAlgoverse(data)
      } catch (e: any) {
        setError(e.message);
      }
    }
    return;
  }

  const _loadBrokerAccountInfo = async () => {
    loadBrokerAccountInfo("SCHWAB");
  };

  const _addLearning = async (type: string, text: string) => {
    await appCommand.api.safeCall(async () => {
      await appCommand.api.postSimpleStore(
        "/api/remote_secure_grodok_algoverse_learning/insert",
        { type: type, message: text },
      );
    });
    _loadLearning();
  };

  const _loadLearning = async () => {
    let res = await appCommand.api.postSimpleStore(
      "/api/remote_secure_grodok_algoverse_learning",
      { _limit: 100 },
    );
    setLearning(res.out);
  };
  const _loadQuoteData = async () => {
    try {
      let data = await SSAlgoTradingDef.getLiveQuote(
        "AAPL",
        appCommand.state?.auth_token_ || "",
      );
      setLiveQuote(data);
    } catch (e: any) {
      setError(e.message);
    }
  };

  // return
  return {
    state: {
      focusTicket,
      BrokerAccountInfo,
      error,
      liveQuote,
      learning,
    },
    api: {
      setError: setError,
      setLiveQuote: setLiveQuote,
      setBrokerAccountInfo: setBrokerAccountInfo,
      loadBrokerAccountInfo: _loadBrokerAccountInfo,
      loadQuoteData: _loadQuoteData,
      submitSimpleOrder: async (
        order: SSAlgoTradingDef.TSimpleOrderPayload,
      ) => {
        let data = await SSAlgoTradingDef.submitSimpleOrder(
          appCommand.state?.auth_token_ || "",
          order,
        );
        appCommand.setNotification({ type: data.type, msg: data.msg });
        _loadBrokerAccountInfo();
        return data;
      },
      cancelOrder: async (order_id: string) => {
        let data = await SSAlgoTradingDef.cancelOrder(
          appCommand.state?.auth_token_ || "",
          {
            order_id: order_id,
            account_hash: BrokerAccountInfo?.account_hash || "",
          },
        );
        appCommand.setNotification({ type: data.type, msg: data.msg });
        _loadBrokerAccountInfo();
        return data;
      },
      addLearning: _addLearning,
      loadLearning: _loadLearning,
    },
  };
};
