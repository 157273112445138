import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { SimpleStoreClient } from "../libs/common_ts/SimpleStoreClient";
import { DefaultAccountScreen } from "../libs/components/screens/DefaultAccountScreen";
import { TAppConfig, setAppConfig } from "../libs/dweb/DAppCommand";
import { DAppInit } from "../libs/dweb/DAppInit";
import { DAppWrapper } from "../libs/dweb/DAppWrapper";
import { DHelmet } from "../libs/dweb/DHelmet";
import { Dashboard } from "./pages/DashboardPage";
import { LadingPage } from "./pages/LandingPage";
import { Pad } from "./pages/PadEditorPage";

// Step1: Init
let appConfig: TAppConfig = {
  app_id: "grodok_pads",
  app_name: "Grodok Pads",
  primary_color: "#19c35f",
  app_slogan: "Worlds Simplest Collaboration Pads",
  app_subtext: "The Only Pad you need to get shit done",
  logo: require("./focus/favicon_io/android-chrome-512x512.png"),
  // DONT ENABLE THIS AS WE WANTS TO USE OLDER VERSION OF FIREBASE
  /*firebase_config: {
    apiKey: "AIzaSyD8LcH-e-0iqkaITRJbqATLYkwmO5PZmmA",
    authDomain: "grodok-pads.firebaseapp.com",
    databaseURL: "https://grodok-pads-default-rtdb.firebaseio.com",
    projectId: "grodok-pads",
    storageBucket: "grodok-pads.appspot.com",
    messagingSenderId: "258794875074",
    appId: "1:258794875074:web:79d811f940811177dcf35b",
    measurementId: "G-QXF353VWX1"
  }*/
};
SimpleStoreClient.Get().init(appConfig);
setAppConfig(appConfig);

function App() {
  return (
    <RecoilRoot>
      <DHelmet config={{ page_title: "Grodok Pads - Only Pads you needs" }} />
      <DAppWrapper>
        <BrowserRouter>
          <DAppInit appConfig={appConfig} />
          <Routes>
            <Route path="/" element={<LadingPage />} />
            <Route path="/account" element={<DefaultAccountScreen />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Pad />} />
          </Routes>
        </BrowserRouter>
      </DAppWrapper>
    </RecoilRoot>
  );
}

export default App;
