import { Delete } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";
import React, { CSSProperties } from "react";
import { DPageHeader } from "../../libs/dweb/DPageHeader";
import { PageHeaderMenu } from "../../libs/dweb/DPageHeaderMenu";
import { DResponsiveWindow } from "../../libs/dweb/DResponsiveWindow";
import { DTable } from "../../libs/dweb/DTable";
import { useEditorHook } from "../helper/EditorHook";
import "./style_override.css";
import { DRefreshButton } from "../../libs/dweb/DRefreshButton";

export function DashboardMenuBar({ style }: { style?: CSSProperties }) {
  return (
    <PageHeaderMenu
      style={style}
      logo={require("../assart/img/logo.png")}
      rightDropDownActions={[
        { text: "Create New Pad", href: "/new" },
        { text: "View all pads", href: "/dashboard" },
      ]}
    />
  );
}

export const Dashboard = () => {
  let editorData = useEditorHook();
  React.useEffect(() => {
    editorData.reload();
  }, []);
  return (
    <DResponsiveWindow className="cf">
      <DashboardMenuBar />
      <DPageHeader
        style={{ marginTop: 80 }}
        title="Pads"
        rightView={
          <>
            <Button
              href="/new"
              variant="contained"
              disabled={!editorData.allowCreate()}
            >
              Create
            </Button>
            <DRefreshButton onClick={editorData.reload} />
          </>
        }
      ></DPageHeader>

      {editorData.allowCreate() == false ? (
        <Alert severity="error" style={{ marginTop: 20, marginBottom: 20 }}>
          You can save at most 10 pads in free account. Please subscribe to save
          unlimited pad or delete from below list to create more{" "}
        </Alert>
      ) : null}

      <DTable
        data={editorData.allPads}
        columns={[
          {
            headerName: "URL",
            field: "pad_id",
            view_type: "link_with_custom_text",
            format: (arg, obj) => [
              window.location.origin + "/" + obj["pad_key"],
              "/" + obj["pad_key"],
            ],
            style: { fontWeight: "bold" },
          },
          {
            field: "title",
            headerName: "Pad Name",
            format: (arg, obj) => {
              return obj.title || "<not defined>";
            },
          },
          {
            field: "is_read_only",
            headerName: "Read Only?",
            format: (arg, obj) => {
              return obj.is_read_only ? "Yes" : "No";
            },
          },
          {
            field: "title",
            headerName: "Private?",
            format: (arg, obj) => {
              return obj._visibility?.indexOf("public") >= 0 ? "public" : "private";
            },
          },

          {
            headerName: "Created Time",
            field: "ts_insert",
            view_type: "time",
          },
          {
            headerName: "Last Updated",
            field: "ts_update",
            view_type: "time",
          },
          {
            headerName: "Action",
            field: "pad_id",
            view_type: "icon_button",
            icon: <Delete />,
            onClick: (item) => {
              editorData.delete(item._id);
            },
          },
        ]}
      />
    </DResponsiveWindow>
  );
};
