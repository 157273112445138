import { Button } from "@mui/material";
import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";
import { DTooltip } from "./DTooltip";

export type TPriceInformation = Array<{
  plan_name: string;
  price: string;
  btn_text?: string;
  btn_link?: string;
  features: Array<{ text: string; hints?: string }>;
}>;

export function DPriceMatrix({
  priceInformation,
  style,
}: {
  priceInformation: TPriceInformation;
  style?: CSSProperties;
}) {
  return (
    <div style={{ maxWidth: 840, margin: "0 auto", ...style }}>
      <DCol>
        <DText style={{ fontSize: 36, textAlign: "center", marginBottom: 40 }}>
          Upgrade to Grodok Pads Pro
        </DText>
        <DRow>
          {priceInformation.map((x, idx) => (
            <DCol
              key={idx}
              style={{
                border: "1px solid #d9dadb",
                borderRadius: 8,
                padding: 24,
                textAlign: "center",
                margin: 12,
              }}
            >
              <DText
                style={{
                  color: "#0074d9",
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: 20,
                }}
              >
                {x.plan_name}
                <br /> <span style={{ fontSize: 14 }}>{x.price}</span>
              </DText>
              {x.features.map((y, idy) => (
                <DText style={{ textAlign: "left" }}>
                  <span style={{ color: "green", paddingRight: 10 }}>✔</span>
                  {y.text} {y.hints ? <DTooltip hints={y.hints} /> : null}
                </DText>
              ))}
              {x.btn_text ? (
                <Button
                  href={x.btn_link}
                  variant="contained"
                  size="small"
                  style={{ marginTop: 20, flex: 0 }}
                >
                  {x.btn_text}
                </Button>
              ) : null}
            </DCol>
          ))}
        </DRow>
        <DText style={{ marginTop: 30, textAlign: "center" }}>
          Payments processed securely with Stripe.<br></br>
          <img
            style={{ marginTop: 10 }}
            src="https://codeshare.io/-/img/powered_by_stripe@3x.png"
            width="120"
            alt="Powered by Stripe"
          ></img>
        </DText>
      </DCol>
    </div>
  );
}
