export namespace CommonTS {
  export function convertToHtml(text: string): string {
    // Replace telephone numbers with HTML links
    //  text = text.replace(/\b(\d{3})[-.]?(\d{3})[-.]?(\d{4})\b/g, '<a href="tel:$1$2$3">$1-$2-$3</a>');
    // Replace email addresses with HTML links
    // text = text.replace(/\b([\w.-]+@[\w.-]+)\b/g, '<a href="mailto:$1">$1</a>');
    // Replace URLs with HTML links
    text = text.replace(/\b((?:https?:\/\/|www\.)\S+)\b/g, '<a href="$1" target="_blank">link</a>');
    // Replace <p> tags with <span> tags
    text = text.replace(/<p>(.*?)<\/p>/g, '<span>$1</span>');
    return text;
  }
  export function formatFileSize(size: number) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (size < kilobyte) {
      return size + ' B';
    } else if (size < megabyte) {
      return (size / kilobyte).toFixed(2) + ' KB';
    } else {
      return (size / megabyte).toFixed(2) + ' MB';
    }
  }

  // get hours diffence 
  export function getHourDifference(isoDateString1?: string, isoDateString2?: string): number | undefined {

    const date1 = new Date(isoDateString1 || '');
    const date2 = new Date(isoDateString2 || '');

    // Check if the date strings are valid
    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      console.error("Invalid date strings");
      return undefined;
    }

    // Calculate the time difference in milliseconds
    const timeDifferenceMs = date2.getTime() - date1.getTime();

    // Convert milliseconds to hours
    const hourDifference = timeDifferenceMs / (1000 * 60 * 60);

    return Math.abs(hourDifference);
  }

  //const dateString = "14 Feb 2026";
  // console.log(convertToISOString(dateString)); // Output: 2026-02-14T00:00:00.000Z
  function convertToISOString(dateString: string): string {
    const [day, month, year] = dateString.split(' ');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = months.indexOf(month);
    if (monthIndex === -1) {
      throw new Error(`Invalid month: ${month}`);
    }
    const date = new Date(Number(year), monthIndex, Number(day));
    return date.toISOString();
  }

  // dd/mm/yyyy to ISO
  export function convertDateToISO(dateStr: string): string {
    // first try dd/mm/yy/
    try {
      // Split the input string and validate the format
      const [day, month, year] = dateStr.split("/").map(Number);

      if (!day || !month || !year || month > 12 || day > 31) {
        throw new Error("Invalid date format or values");
      }

      // Create a Date object
      const date = new Date(year, month - 1, day);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      // Return the ISO string
      return date.toISOString();
    } catch (error) {

    }

    // when date string like "14 Feb 2026";
    try {
      return convertToISOString(dateStr)
    } catch (error) {

    }
    throw new Error(`Failed to convert date: ${dateStr}`);
  }

  // Test the function
  //  console.log(numberToWords(1087827.97));  // Output: "One Million, Eighty-Seven Thousand, Eight Hundred Twenty-Seven and Ninety-Seven Cents"
  export function numberToWords(num: number): string {
    const ones = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const tens = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
    const thousands = ['', 'Thousand', 'Million', 'Billion'];

    function convertToWords(n: number): string {
      if (n === 0) return 'Zero';

      let result = '';
      let groupIndex = 0;

      while (n > 0) {
        let group = n % 1000;
        if (group > 0) {
          let groupWords = '';
          if (group >= 100) {
            groupWords += ones[Math.floor(group / 100)] + ' Hundred ';
            group %= 100;
          }
          if (group >= 20) {
            groupWords += tens[Math.floor(group / 10)] + ' ';
            group %= 10;
          }
          if (group > 0) {
            groupWords += ones[group] + ' ';
          }
          result = groupWords + thousands[groupIndex] + ' ' + result;
        }
        n = Math.floor(n / 1000);
        groupIndex++;
      }

      return result.trim();
    }

    const integerPart = Math.floor(num);
    const decimalPart = Math.round((num - integerPart) * 100);

    let words = convertToWords(integerPart);

    if (decimalPart > 0) {
      words += ' and ' + convertToWords(decimalPart) + ' Cents';
    }

    return words;
  }

  //console.log(formatNumberWithCommas(1087827.97));  // Output: "1,087,827.97"
  export function formatNumberWithCommas(num: number): string {
    try {
      return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } catch (e) {
      return '0.00'
    }
  }

}