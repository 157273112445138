import { TVoidCalBack, dassert, dlog } from "corexxx";
import { DWebTS } from "../../libs/common_ts/DWebTS";
import { PluginsManager } from '../plugins/PluginsManager';
import { TEditorData } from "./EditorHook";

interface IEditorCallback {
    onLoading(): void
    onReady(): void
    onError(msg: string): void;
}

export class EditorHelper {
    private static instance: EditorHelper | null = null;
    private _inited_dom = false;
    private _inited_connection = false

    // public access veriable
    public editor = null
    public simpleStoreEntry: any = null
    public firebaseRef: any = null;
    public callbackList: Array<IEditorCallback> = []


    // Private constructor to prevent creating instances from outside the class
    private constructor() {
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        // Attach the event listener to the window
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload(event: any) {
        for (var c of this.callbackList) { c.onLoading() }
        PluginsManager.getInstance().getPluginList().map(x => {
            x.onDisconnected(this.editor, this.firebaseRef)
        })
        event.returnValue = ''; // Standard for most browsers
    }

    // Public method to get or create the singleton instance
    public static getInstance(): EditorHelper {
        if (!EditorHelper.instance) {
            EditorHelper.instance = new EditorHelper();
        }
        return EditorHelper.instance;
    }



    // Other methods or properties of the singleton class can be added here
    public async init(onInitDomSuccess: TVoidCalBack) {
        if (this._inited_dom) {
            dlog.d("ignore init")
            return;
        }
        dlog.d("executing init once")
        this._inited_dom = true
        try {
            for (var c of this.callbackList) { c.onLoading() }
            await DWebTS.loadScript("https://www.gstatic.com/firebasejs/7.13.2/firebase-app.js");
            await DWebTS.loadScript("https://www.gstatic.com/firebasejs/7.13.2/firebase-auth.js");
            await DWebTS.loadScript("https://www.gstatic.com/firebasejs/7.13.2/firebase-database.js");

            //2. Ace Editor 
            await DWebTS.loadScript("https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/ace.js");
            await DWebTS.loadScript("https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/mode-javascript.js");
            await DWebTS.loadScript("https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/theme-textmate.js");
            await DWebTS.loadScript("https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/theme-tomorrow_night.js");

            //3. Firepad
            await DWebTS.loadScript("https://unpkg.com/firepad@1.5.11/dist/firepad.min.js")
            await DWebTS.loadCSS("https://unpkg.com/firepad@1.5.11/dist/firepad.css")

            //@ts-ignore
            firebase.initializeApp({
                apiKey: 'AIzaSyD8LcH-e-0iqkaITRJbqATLYkwmO5PZmmA',
                databaseURL: 'https://grodok-pads-default-rtdb.firebaseio.com'
            });
            dlog.d("init of CSS and Firebase is done")
            onInitDomSuccess();
        } catch (e: any) {
            dlog.d("All init error")
            console.error(e)
            for (var c of this.callbackList) { c.onError(e.message) }
        }

    }

    public async initConnection(firebase_key: string, data: TEditorData, onConnectionSuccess?: TVoidCalBack, onConnectionError?: (e: Error) => void) {
        dassert.verifyOrCrash(this._inited_dom, "not ready yet")
        if (this._inited_connection) {
            dlog.d("ignore _inited_connection")
            return;
        }
        dlog.d("executing init once")
        this._inited_connection = true
        try {
            dlog.d("initConnection called")
            //@ts-ignore
            var ref = firebase.database().ref();
            //@ts-ignore
            this.firebaseRef = ref.child(firebase_key);

            //@ts-ignore
            var editor = ace.edit("firepad");
            this.editor = editor
            //editor.setTheme("ace/theme/textmate");
            editor.setTheme("ace/theme/tomorrow_night");
            var session = editor.getSession();
            session.setUseWrapMode(true);
            session.setUseWorker(false);
            session.setMode("ace/mode/javascript");

            //@ts-ignore
            var firepad = Firepad.fromACE(this.firebaseRef.child('code-diff'), editor, {
                defaultText: '// Welcome to Grodok Pads!!!',
                userId: data.name,
                userColor: data.color,
                userName: data.name
            });
            dlog.d("All init done")
            for (var c of this.callbackList) { c.onReady() }
            onConnectionSuccess?.()
        } catch (e) {
            onConnectionError?.(e as Error)
        }
        //PluginsManager.getInstance().getPluginList().map(x => x.onConnected(this.editor, this.getExampleRef))
    }

    public async createNewFirebaseKey() {
        dassert.verifyOrCrash(this._inited_dom, "not ready yet")
        // @ts-ignore
        var ref = firebase.database().ref();
        ref = ref.push(); // generate unique location.
        return ref.key;
    }
}
