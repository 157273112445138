import { dlog, drandom } from "corexxx";
import React, { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { DHook } from "../../libs/common_hooks/DHook";
import { CommonTS } from "../../libs/common_ts/corexxx-ext";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { EditorHelper } from "./EditorHelper";
import { DWebTS } from "../../libs/common_ts/DWebTS";
// check https://ace.c9.io/build/kitchen-sink.html
export type TEditorData = {
  name: string;
  color: string;
};

export type TPadInfo = {
  _id: string;
  pad_key: string;
  firebase_key: string;
  user_id: string;
  ts_insert: string;
  ts_update: string;
  // extra
  _username?: string;
  title?: string;
  _visibility?: string[];
  is_read_only?: boolean;
};

export type TPadInfoInput = {
  pad_key?: string;
  firebase_key?: string;
  user_id?: string;
  title?: string;
  ts_insert?: string;
  ts_update?: string;
};

const END_POINT = "/api/secure_remote_grodok_pads";
//const END_POINT = 'http://localhost/api/remote_grodok_pads'
const { persistAtom } = recoilPersist();

const allPadsAtom = atom<TPadInfo[]>({
  key: "all_pads",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export function useEditorHook() {
  const appCommand = useDAppCommand();
  const padInfoStore = DHook.useSimpleStore<TPadInfo>(END_POINT, { allowAnonymous: true });
  const [allPads, setAllPads] = useRecoilState(allPadsAtom);
  const [currentPad, setCurrentPad] = useState<TPadInfo>()
  const [users, userFunc] = DHook.useObject<any>({});
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [setupReady, setSetupReady] = useState<boolean>(false);
  const [padExpHrs, setPadExp] = useState<number | null>();

  // syntax
  const [syntax, setSyntax] = React.useState("");
  React.useEffect(() => {
    if (syntax.length > 0) {
      // @ts-ignore
      EditorHelper.getInstance().editor.getSession().setMode(syntax);
    }
  }, [syntax]);

  // keymap
  const [keymap, setkeymap] = React.useState("");
  React.useEffect(() => {
    if (keymap.length > 0) {
      // @ts-ignore
      EditorHelper.getInstance().editor?.setKeyboardHandler(keymap);
    } else {
      //EditorHelper.getInstance().editor?.setKeyboardHandler(null)
    }
  }, [keymap]);

  // theme
  const [theme, setTheme] = React.useState("");
  React.useEffect(() => {
    if (theme.length > 0) {
      // @ts-ignore
      EditorHelper.getInstance().editor?.setTheme(theme);
    } else {
      // @ts-ignore
      EditorHelper.getInstance().editor?.setTheme("ace/theme/tomorrow_night");
    }
  }, [theme]);

  // fontSize
  const [fontSize, setFontSize] = React.useState("");
  React.useEffect(() => {
    if (fontSize != "") {
      // @ts-ignore
      EditorHelper.getInstance().editor?.setFontSize(parseInt(fontSize));
    }
  }, [fontSize]);

  // white space:
  const [showWhiteSpace, setShowWhiteSpace] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    EditorHelper.getInstance().editor?.setOption(
      "showInvisibles",
      showWhiteSpace,
    );
  }, [showWhiteSpace]);

  // Function that should be called only once
  const setupFunction = () => {
    if (!hasBeenCalled) {
      const handleDocumentLoad = async () => {
        try {
          await EditorHelper.getInstance().init(() => {
            setSetupReady(true);
          });
        } catch (e: any) {
          setError(`Not able to start the Engine. ${e.message}`);
        }
      };
      window.addEventListener("load", handleDocumentLoad);
      setHasBeenCalled(true);
    }
  };

  const reload_ = async () => {
    setAllPads(await padInfoStore.api.getAll());
  }

  useEffect(() => {
    setupFunction();
  }, []);

  useEffect(() => {
    if (currentPad && setupReady) {
      dlog.d("Initilizing the pad " + currentPad._id)
      initializePad(currentPad);
    }
  }, [currentPad, setupReady]);

  async function createOrGetPad() {
    //@ts-ignore
    let pad_key = window.location.pathname.substring(1).trim();
    if (pad_key == "new") {
      createNewPad();
      return;
    }

    if (pad_key.length == 0) {
      pad_key = localStorage.getItem("pad_key") as string;
    }

    // try to fetch pad key from simplestore
    let data = await padInfoStore.api.findOne({ pad_key: pad_key });
    if (data != null) {
      if (data._username == "anonymous") {
        const lastDate = new Date(); // The epoch time
        lastDate.setHours(lastDate.getHours() - 24);
        let exp = CommonTS.getHourDifference(
          data.ts_insert,
          lastDate.toISOString(),
        );
        if (exp && exp >= 24) {
          setError(`This pad of id ${pad_key} has expired and deleted.`);
          await padInfoStore.api.delete(data._id, true);
          return;
        }
        setPadExp(exp);
      }
      setCurrentPad(data);
    } else {
      setError(`Not able to find requested pad with id:${pad_key}`);
    }
  }

  async function initializePad(padInfo: TPadInfo) {
    EditorHelper.getInstance().initConnection(
      padInfo.firebase_key,
      {
        color: DWebTS.getRandomLightColor(),
        name: appCommand.accountState?.username || "guest",
      },
      () => {
        // 1 update localcation.
        var currentUrl = new URL(window.location.href);
        var url = new URL(padInfo.pad_key, currentUrl);
        history.pushState(null, "", url.href);
        //2. hide
        const elementToHide = document.querySelector(".powered-by-firepad");
        if (elementToHide) {
          //@ts-ignore
          elementToHide.style.display = "none";
        }

        //3. Update the aprtcipant
        EditorHelper.getInstance()
          .firebaseRef?.child("code-diff")
          ?.child("users")
          ?.on("value", (newVal: any) => {
            try {
              let data = newVal.val();
              userFunc.reset(
                Object.keys(data).map((key) => ({
                  name: key,
                  color: data[key].color,
                })),
              );
            } catch (e) {
              dlog.ex(e as Error);
            }
          });
      },
      (e: Error) => {
        setError(`Not able to connect the server. ${e.message}`);
      },
    );
  }

  async function createNewPad() {
    setError(undefined);
    if (!allowCreate()) {
      setError(
        "You can save at most 10 pads in your account. Please consider subscribe or delete some pad from account page.",
      );
      return;
    }
    try {
      let padInfo: TPadInfo = {
        title: "Untitled",
        firebase_key: await EditorHelper.getInstance().createNewFirebaseKey(),
        pad_key: drandom.getRandomId(10),
        user_id: appCommand.accountState?.username || "guest",
        _visibility: ["public"], // by default public
        _id: "",
        ts_insert: "",
        ts_update: "",
      };
      let data = await padInfoStore.api.create(padInfo);
      setCurrentPad(data)
      padInfoStore.api.addVisibility(data._id, "public")
    } catch (e: any) {
      setError(`Not able to create new pad ${e.message}`);
    }
  }

  function allowCreate() {
    if (!appCommand.accountState?._id) {
      return true;
    }
    return allPads.length <= 10;
  }

  function isGuestPad() {
    return currentPad?._username == "anonymous";
  }

  function updateContent(data: string) {
    if (appCommand.accountState?._id) {
      padInfoStore.api.update(currentPad?._id || "", { data: data }, { silentLoading: true, silentNotification: true });
    } else {
      dlog.e("Ignore update ")
    }
  }

  const delete_ = async (_id: string) => {
    await padInfoStore.api.delete(_id);
    await reload_()
  }

  // Return data and the function to update data
  return {
    users,

    // pad operation
    currentPad,
    allPads,
    reload: reload_,
    delete: delete_,
    createOrGetPad,
    createNewPad,
    updateContent,

    syntax,
    setSyntax,

    theme,
    setTheme,

    fontSize,
    setFontSize,

    keymap,
    setkeymap,

    showWhiteSpace,
    setShowWhiteSpace,

    error,
    setError,

    setupReady,
    padInfoStore,

    allowCreate,
    isGuestPad,

    padExpHrs,
  };
}
