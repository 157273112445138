import { TObject, dlog, dnetwork } from "corexxx";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useCookies } from 'react-cookie';
import { useRecoilState } from "recoil";
import { TPadInfo } from "../../pads/helper/EditorHook";
import { DWebTS } from "../common_ts/DWebTS";
import { TRequestOverride, TSafeCallConfig, useDAppCommand } from "../dweb/DAppCommand";

export namespace DHook {
  /*
 Ho to use?
const path = useReactPath();
React.useEffect(() => {
  // do something when path changes ...
}, [path]);
*/
  function getStorageValue(key: string, defaultValue: TObject) {
    const saved = localStorage.getItem(key) as string;
    const initial = JSON.parse(saved);
    return initial || defaultValue;
  }

  export const useLocalStorage = (key: string, defaultValue: TObject) => {
    const [value, setValue] = React.useState(() => {
      return getStorageValue(key, defaultValue);
    });

    React.useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value, defaultValue]);

    return [value, setValue];
  };

  export const useReactPath = () => {
    const [path, setPath] = React.useState(window.location.pathname);
    const listenToPopstate = () => {
      const winPath = window.location.pathname;
      setPath(winPath);
    };
    React.useEffect(() => {
      window.addEventListener("popstate", listenToPopstate);
      return () => {
        window.removeEventListener("popstate", listenToPopstate);
      };
    }, []);
    return path;
  };

  export function useDeviceType() {
    const [deviceType, setDeviceType] = useState<"mobile" | "tablet" | "desktop" | null>(null);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setDeviceType("mobile");
        } else if (window.innerWidth < 1024) {
          setDeviceType("tablet");
        } else {
          setDeviceType("desktop");
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return deviceType;
  }

  interface ArrayHelpers<T> {
    push: (item: T) => void;
    pop: () => void;
    clear: () => void;
    removeAtIndex: (index: number) => void;
    insertAtIndex: (index: number, item: T) => void;
    move: (fromIndex: number, toIndex: number) => void;
    filter: (callback: (item: T) => boolean) => void;
    map: <U>(callback: (item: T) => U) => void;
    reduce: <U>(callback: (total: U, item: T) => U, initialValue: U) => void;
    updateAtIndex: (index: number, item: T) => void;
  }

  export function useArray<T>(initialValue: T[]): [T[], ArrayHelpers<T>] {
    const [array, setArray] = useState<T[]>(initialValue);

    function push(item: T) {
      setArray([...array, item]);
    }

    function pop() {
      const newArray = [...array];
      newArray.pop();
      setArray(newArray);
    }

    function clear() {
      setArray([]);
    }

    function updateAtIndex(index: number, item: T) {
      const newArray = [...array];
      newArray[index] = item;
      setArray(newArray);
    }

    function removeAtIndex(index: number) {
      const newArray = [...array];
      newArray.splice(index, 1);
      setArray(newArray);
    }

    function insertAtIndex(index: number, item: T) {
      const newArray = [...array];
      newArray.splice(index, 0, item);
      setArray(newArray);
    }

    function move(fromIndex: number, toIndex: number) {
      const newArray = [...array];
      const item = newArray.splice(fromIndex, 1)[0];
      newArray.splice(toIndex, 0, item);
      setArray(newArray);
    }

    function filter(callback: (item: T) => boolean) {
      const newArray = array.filter(callback);
      setArray(newArray);
    }

    function map<U>(callback: (item: T) => U) {
      const newArray = array.map(callback);
      //@ts-ignore
      setArray(newArray);
    }

    function reduce<U>(callback: (total: U, item: T) => U, initialValue: U) {
      const result = array.reduce(callback, initialValue);
      //@ts-ignore
      setArray([result]);
    }

    const helpers: ArrayHelpers<T> = {
      push,
      pop,
      clear,
      removeAtIndex,
      insertAtIndex,
      move,
      filter,
      map,
      reduce,
      updateAtIndex
    };

    return [array, helpers];
  }

  interface ObjectHelpers<T> {
    set: (key: keyof T, value: T[keyof T]) => void;
    remove: (key: keyof T) => void;
    reset: (givenValue?: T) => void;
    update: (key: keyof T, updater: (value: T[keyof T]) => T[keyof T]) => void;
    merge: (newObject: Partial<T>) => void;
  }

  export function useObject<T extends object>(initialValue: T): [T, ObjectHelpers<T>] {
    const [object, setObject] = useState<T>(initialValue);

    function set(key: keyof T, value: T[keyof T]) {
      setObject({ ...object, [key]: value });
    }

    function remove(key: keyof T) {
      const { [key]: _, ...newObject } = object;
      //@ts-ignore
      setObject(newObject);
    }

    function reset(givenValue?: T) {
      setObject(givenValue || initialValue || {});
    }

    function update(key: keyof T, updater: (value: T[keyof T]) => T[keyof T]) {
      const value = object[key];
      set(key, updater(value));
    }

    function merge(newObject: Partial<T>) {
      setObject({ ...object, ...newObject });
    }

    const helpers: ObjectHelpers<T> = {
      set,
      remove,
      reset,
      update,
      merge,
    };

    return [object, helpers];
  }

  export type TItem = {
    _id: string;
    ts_insert: string;
    ts_update: string
  }

  // This is a hook for Simplestore
  // This must be used your own hook
  // This should be state less, It just provied some api which is the wrapper fo the SimpleStoreEndpoint.
  export function useSimpleStore<T extends TItem>(url: string, config?: TRequestOverride) {
    const appCommand = useDAppCommand()
    const [loading, setLoading] = useState(false)

    // Create a new item in the database and return the data.
    const createItem = async (item: T): Promise<T> => {
      return await appCommand.fireAndForget(async () => {
        setLoading(true);
        (item as TObject)._id = undefined
        let res = await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/create', item, config)
        let new_item = res.out[0]
        setLoading(false)
        return new_item;
      })
    };

    // Update an existing item in the database and return the data,
    const updateItem = async (_id: string, input: TObject, config2?: TSafeCallConfig): Promise<T> => {
      return appCommand.fireAndForget(async () => {
        setLoading(true)
        await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/update', { ...input, _id: _id }, config, config2)
        let res = await getOne(_id, false)
        setLoading(false)
        return res;
      })
    };

    // Delete an item from the database
    // pass silentDelete as true to avoid confirmation dialog
    const deleteItem = async (_id: string, silentDelete = false) => {
      if (silentDelete) {
        await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/delete', { _id: _id }, config)
        return;
      }

      DWebTS.showConfirmationAndDo(async () => {
        await appCommand.fireAndForget(async () => {
          setLoading(true)
          try {
            await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/delete', { _id: _id }, config)
          } catch (e) { }
          setLoading(false)
        })
      })
    };

    const getOne = async (_id: string, cache: boolean = true): Promise<T> => {
      let res = await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/get', { _id: _id }, config)
      return res.out[0]
    }

    const findOne = async (filter: TObject): Promise<T | null> => {
      let res = await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/get', filter, config)
      if (res.out.length == 0) {
        return null
      }
      return res.out[0]
    }


    // Get all items from the database
    const getAllItems = async (): Promise<T[]> => {
      return appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          let res = await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url, { _limit: 100 }, config)
          return res.out
        } catch (e) {
        }
        setLoading(false)
        return []
      })
    };

    const subEntryAdd = async (_id: string, key: string, value: TObject): Promise<T | undefined> => {
      return appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/subentry/add', { _id: _id, key: key, value: value }, config)
          return await getOne(_id, false)
        } catch (e) {
        }
        setLoading(false)
        return undefined;
      })
    }

    const subEntryUpdate = async (_id: string, key: string, entry_id: string, value: TObject): Promise<T | undefined> => {
      return appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/subentry/update', { _id: _id, entry_id: entry_id, key: key, value: value }, config)
          return await getOne(_id, false)
        } catch (e) {
          return null
        }
        setLoading(false)
      })
    }

    const subEntryDelete = async (_id: string, key: string, entry_id: string) => {
      return appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/subentry/remove', { _id: _id, entry_id: entry_id, key: key }, config)
          await getOne(_id, false)
        } catch (e) { }
        setLoading(false)
      })
    }

    const _addVisiblity = async (_id: string, user_name: string) => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/visibility/add', { _id: _id, username: user_name }, config)
          await getOne(_id, false)
        } catch (e) { }
        setLoading(false)
      })
    }

    const _removeVisiblity = async (_id: string, user_name: string) => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.api.postSimpleStore(appCommand.state.simpleStoreEndpoint + url + '/visibility/remove', { _id: _id, username: user_name }, config)
          await getOne(_id, false)
        } catch (e) { }
        setLoading(false)
      })
    }

    return {
      loading: loading,
      api: {
        create: createItem,
        update: updateItem,
        delete: deleteItem,
        getAll: getAllItems,
        getOne: getOne,
        findOne: findOne,

        // subentry
        subEntryAdd: subEntryAdd,
        subEntryDelete: subEntryDelete,
        subEntryUpdate: subEntryUpdate,
        // visibility apis
        addVisibility: _addVisiblity,
        removeVisibility: _removeVisiblity,
        //search: serach
      }
    }
  }

  export const useAsyncEffect = (effect: any, dependencies: any) => {
    const isMountedRef = useRef(true);

    useEffect(() => {
      const cleanup = effect();

      return () => {
        isMountedRef.current = false;
        if (typeof cleanup === 'function') {
          cleanup();
        }
      };
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

    return isMountedRef;
  };


  // Make sure this works for string only
  export const useStateWithCookie = (initialState: string, cookieName: string): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [state, setState] = useState<string>(initialState);
    const [cookies, setCookie] = useCookies([cookieName]);

    useEffect(() => {
      const savedState = cookies[cookieName];
      dlog.d(`Restore for ${cookieName} to ${savedState}`)
      if (savedState) {
        if (typeof (savedState) == 'object') {
          setState(JSON.stringify(savedState, null, '\t'))
        } else {
          setState(savedState.toString());
        }
      }
    }, []);

    useEffect(() => {
      setCookie(cookieName, state, { path: '/' });
    }, [state]);

    return [state, setState];
  };

  // This hook will abstrcut JSOn network request
  export const useNetwork = (url: string, config?: { isSimpleStoreEndpoint?: boolean, success_msg?: string, error_msg?: string }) => {
    const [data, setData] = useState<TObject | null>(null);
    const [params, setParams] = useState<TObject | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const appComamnd = useDAppCommand()

    const fetchData = async () => {
      setLoading(true);
      appComamnd.api.setLoading(true)
      try {
        if (url.startsWith('/')) {
          url = appComamnd.state.simpleStoreEndpoint + url
        }
        const response = await dnetwork.postJson(url, params || {}) as any;
        if (config?.isSimpleStoreEndpoint) {
          setData(response.out as TObject)
        } else {
          setData(response);
        }
        appComamnd.api.setNotification({ type: 'success', msg: config?.success_msg || 'Done' })
        setError(null);
      } catch (error: any) {
        appComamnd.api.setNotification({ type: 'error', msg: config?.error_msg || 'Error' })
        setError(error.msg);
        setData(null)
      }
      appComamnd.api.setLoading(false)
      setLoading(false);
    };

    useEffect(() => {
      fetchData();
    }, [url, params]);

    const reload = () => {
      fetchData();
    };

    // You must return as state and api
    return {
      state: {
        data: data,
        error: error,
        loading: loading,
        params: params
      },
      api: {
        reload: reload,
        setParams: (obj: TObject) => setParams(obj),
      }
    }
  }

  // use this hook to load css only for that componet
  export const useLoadCSS = (cssFiles: string[]): boolean => {
    const [isLoaded, setIsLoaded] = useState(false);

    useLayoutEffect(() => {
      const loadCSS = async () => {
        try {
          // Dynamically import all CSS files
          await Promise.all(cssFiles.map(file => import(file)));
          setIsLoaded(true);
        } catch (error) {
          console.error('Error loading CSS files:', error);
        }
      };

      loadCSS();
    }, [cssFiles]);

    return isLoaded;
  };

  // get the polling data ()
  export const useAutoPoolingData = (url: string, timeoutInSec: number, transformFn?: (obj: TObject) => TObject) => {
    const [data, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        setError(undefined)
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not 200. May be URL is not working any-more');
          }
          const result = await response.json();
          if (result?.status == 'error') {
            throw new Error(result?.msg || 'Simplestore returns error');
          }
          setData(transformFn ? transformFn?.(result) : result);
          setError(undefined);
        } catch (err: any) {
          setError(err.message);
          setData(undefined);
        } finally {
          setLoading(false);
        }
      };

      // Initial fetch
      fetchData();

      // Set up interval for fetching data
      const intervalId = setInterval(fetchData, timeoutInSec * 1000);

      // Cleanup on unmount or if URL/timeout changes
      return () => clearInterval(intervalId);
    }, [url, timeoutInSec]);

    return { data, loading, error };
  }

  // Very helpful for debugging:  use this hook to log compoint mouted and unmounted
  export const useMountLogger = (componentName: string) => {
    const renderCount = useRef(0);
    useEffect(() => {
      if (renderCount.current === 0) {
        dlog.i(`${componentName} mounted`);
      } else {
        dlog.i(`${componentName} re-rendered count:${renderCount.current}`);
      }
      renderCount.current += 1;
      return () => {
        dlog.i(`${componentName} unmounted after count:${renderCount.current} renders`);
      };
    });
  }
}
