import { BasePlugin } from "./BasePlugin";
import { ParticipantListPlugin } from "./ParticipentListPlugin";

export class PluginsManager {
  private static instance: PluginsManager | null = null;
  private constructor() {
    // Private constructor to prevent instantiation
  }
  public static getInstance(): PluginsManager {
    if (!PluginsManager.instance) {
      PluginsManager.instance = new PluginsManager();
    }
    return PluginsManager.instance;
  }
  public getPluginList(): Array<BasePlugin> {
    return [new ParticipantListPlugin()];
  }
}
